import Footer from './Footer.svelte';
import NavBar from './NavBar.svelte';
import Settings from './SettingsModal.svelte';
// import HelloWorld from './HelloWorld.svelte';
// import Electron from './Electron.svelte';

new Footer({
  target: document.querySelector('#footer')
});

new NavBar({
  target: document.querySelector('#topNavbar')
});

new Settings({
  target: document.querySelector('#settingsModal')
});

// const newhelloWorld = new HelloWorld({
//   target: document.querySelector('#helloWorld'),
// 	props: {
// 		name: "New Boss"
// 	}
// });

// new Electron({
//   target: document.querySelector('#svelteElectron')
// });